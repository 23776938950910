import Select from "react-select";
import Label from "../Label/Label";
import "./CustomSelect.scss";
import { useDispatch } from "react-redux";
import { setChain } from "../../../../Redux/slices/admin.slice";

const CustomSelect = (props: {
	options?: any;
	menuIsOpen?: boolean;
	isSearchable?: boolean;
	defaultValue?: any;
	label?: string | number | undefined;
	className?: string | undefined;
	setDate?: any;
	chain?: boolean
}) => {
	const dispatch = useDispatch();
	return (
		<div
			className={`common-select-wrapper  ${
				props.className ? props.className : ""
			}`}
		>
			{props.label && <Label label={props.label} />}
			<Select
				options={props.options}
				className="common-select"
				classNamePrefix="react-select"
				menuIsOpen={props.menuIsOpen}
				isSearchable={props.isSearchable}
				defaultValue={props.defaultValue}
				onChange={(e) => { props?.chain ? dispatch(setChain(e)) : props?.setDate(e) }}
			/>
		</div>
	);
};

export default CustomSelect;
