import { toast } from "../Components/Layout/Toast/Toast";
import { setJwt, setRefreshToken } from "../Redux/slices/admin.slice";
import store from "../Redux/store";
import { APIURL } from "../utils/constants";
import {
	apiCallGet,
	apiCallPatchHeader,
	apiCallPostHeader,
} from "./axiosServices";

export const loginUser = async (email: string, password: string) => {
	try {
		const result: any = await apiCallPostHeader(
			APIURL.LOGIN_USER,
			{
				email,
				password,
			},
			{}
		);
		if (result) {
			return result.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};

export const logoutUser = async (token: string) => {
	try {
		const result: any = await apiCallPostHeader(
			APIURL.LOGOUT_USER,
			{
				token,
			},
			{}
		);
		if (result) {
			return result.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};

export const forgotPwd = async (email: string) => {
	try {
		const result: any = await apiCallPostHeader(
			APIURL.FORGOT_PWD,
			{
				email,
			},
			{}
		);
		if (result) {
			return result?.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};

export const resetPwd = async (
	token: string,
	password: string,
	confirmPassword: string
) => {
	try {
		const result: any = await apiCallPatchHeader(
			APIURL.RESET_PWD,
			{
				token,
				password,
				confirmPassword,
			},
			{}
		);
		if (result) {
			return result?.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};

export const sendOtpCode = async (email: string, otp: string) => {
	try {
		const result: any = await apiCallPostHeader(
			APIURL.VERIFY_OTP_CODE,
			{
				email,
				otp,
			},
			{}
		);
		if (result) {
			return result?.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};

export const getAllTrnxLogs = async (token: string, currentPage: number) => {
	try {
		const chain = store?.getState()?.admin?.chain;
		const result: any = await apiCallGet(chain?.label == "ETH" ? APIURL.TRNX_HISTORY : APIURL.TRNX_HISTORY_BASE, token, "", {
			page: currentPage,
		});
		if (result) {
			return result?.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};

export const getGraphData = async (
	start: string,
	end: string,
	token: string
) => {
	try {
		const result: any = await apiCallPostHeader(
			APIURL.GRAPH,
			{
				start,
				end,
			},
			{},
			token
		);
		if (result) {
			return result?.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};

export const fetchToken = async (refreshToken: string) => {
	try {
		const result: any = await apiCallGet(
			APIURL.REFRESH_TOKEN,
			"",
			refreshToken
		);
		if (result) {
			store.dispatch(setJwt(result?.data?.accessTokenVal));
			store.dispatch(setRefreshToken(result?.data?.refreshTokenVal));
			return result?.data;
		}
	} catch (error: any) {
		toast.error(error?.message);
	}
};
// export const getStakingData = async (chain: string, walletAddress: string,page:number, pageSize:number) => {
//   try {
//     ////////////////TODO
//     const result: any = await apiCallPostHeader(
//       APIURL?.GET_STAKE_DATA,
//       {
//         chain: chain,
//         userAddress: walletAddress,
//       },
//       {
//         page,
//         pageSize
//       }
//     );
//     if (result) {
//       return{ result : result.data.data, totalCount : result.data.dataSize}
//     }
//   } catch (error: any) {
//     console.log(error.mesage);
//   }
// };

// export const getFarmData = async (chain: string, walletAddress: string) => {
//   try {
//     ////////////////TODO
//     const result: any = await apiCallPostHeader(
//       APIURL?.GET_FARM_DATA,
//       {
//         chain: chain,
//         userAddress: walletAddress,
//       },
//       {}
//     );
//     if (result) {
//       return result.data.data;
//     }
//   } catch (error: any) {
//     console.log(error.mesage);
//   }
// };
