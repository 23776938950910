export const SITE_URL: any = "https://stage-api.arbitraze.io/v1/admin";
export const SOCKET_URL: any = "http://57.181.31.34:3001";

export const APIURL = {
	LOGIN_USER: "/login",
	FORGOT_PWD: "/password/forget",
	VERIFY_OTP_CODE: "/verify-otp",
	RESEND_OTP_CODE: "/otpResend",
	RESET_PWD: "/password",
	DASHBOARD: "/dashboard",
	TRNX_HISTORY: "/logs",
	TRNX_HISTORY_BASE: "/logs/base",
	REFRESH_TOKEN: "/refresh-token",
	LOGOUT_USER: "/logOut",
	GRAPH: "/graph-data",
};
