import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-code-input";
import CustomModal from "../CustomModal";
import CustomButton from "../../CustomButton/CustomButton";
import Label from "../../Formik/Label/Label";
import "./VerifyEmailModal.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { forgotPwd } from "../../../../services/apiServices";
import { toast } from "../../../Layout/Toast/Toast";

const VerifyEmailModal = ({
	show,
	onHide,
	submitFun,
}: {
	show?: boolean;
	onHide?: any;
	submitFun?: any;
}) => {
	const adminEmail = useSelector((state: any) => state.admin.email);
	const [userOtp, setUserOtp] = useState<string>("");
	const handleOtpChange = (value: any) => {
		setUserOtp(value);
	};
	const resendOTP = async()=>{
		const forgotPwdResult = await forgotPwd(adminEmail);
		if (forgotPwdResult?.status == 200) {
			toast.success(forgotPwdResult?.message);
		}
	}
	return (
		<>
			<CustomModal
				show={show}
				onHide={onHide}
				title="Verify Your Email"
				closeButton
				className="verify-modal"
			>
				<Label label="Please Enter the 6 Digit Verification Code" />
				<ReactCodeInput
					type="number"
					fields={6}
					name="verificationCode"
					inputMode="numeric"
					onChange={handleOtpChange}
				/>
				<CustomButton
					text="Submit"
					className="w-100"
					onClick={() => submitFun(userOtp)}
				/>
				<div className="verify-modal__resend" onClick={()=>resendOTP()}>
					<span>Resend OTP</span>
				</div>
			</CustomModal>
		</>
	);
};

export default VerifyEmailModal;
